import { collection, getDocs, setDoc, getFirestore,deleteDoc,  doc, updateDoc } from "firebase/firestore";
import { notify } from "@kyvg/vue3-notification";
import { v4 as uuidv4 } from 'uuid';
const db = getFirestore();



export const setData = async (payload, coll) => {
  let newId = uuidv4()
  await setDoc(doc(db, coll, newId), {
    approved: false,
    author: payload.author,
    date: payload.date,
    message: payload.message,
    rating: payload.rating,
    id: newId
  })
  .then(() => {
      notify({
      type: "success",
      title: `Submission`,
      text: "Submitted review, Thank you!",
    }); 
  })
}

export const getData = async (coll) => {
  let resArray = []
  let data = await getDocs(collection(db, coll))
  
  if(data) {
    data.forEach((doc) => {
      resArray.push(doc.data())
    });
    return resArray
  }
}

export const getSiteData = async () => {
  let resArray = undefined
  let data = await getDocs(collection(db, "siteData"))
  
  if(data) {
    data.forEach((doc) => {
      resArray = doc.data()
    });
    return resArray
  }
}

export const getBookedDates = async () => {
  let resArray = []
  let data = await getDocs(collection(db, "booked_dates"))
  if(data) {
    data.forEach((doc) => {
      resArray.push(doc.data())
    });
    return resArray
  }
}

export const setBookedDate = async (payload, coll) => {
  let newId = uuidv4()
  await setDoc(doc(db, coll, newId), {
    start: payload.start,
    end: payload.end,
    title: payload.title,
    content: payload.content,
    id: newId
  })
  .then(() => {
      notify({
      type: "success",
      title: `Booking`,
      text: "Booked date succesfully",
    }); 
  })
}

export const deleteBooking = async (coll, id) => {
  console.log("DElete")
  await deleteDoc(doc(db, coll, id))
  .then(() => {
    notify({
      type: "error",
      title: `Delete`,
      text: "Deleted booking",
      }); 
  }).
  catch((e) => {
    console.log(e);
  })
}

export const updateReview =  async (state, coll, docuId) => {
  const review = doc(db, coll, docuId);
  await updateDoc(review, {
    approved: state
  }).then(() => {
    notify({
      type: "success",
      title: `Update`,
      text: `Updated review to ${state ? "Approved" : "Not approved"}`,
      }); 
  })
}


export const updateData =  async (payload, coll, docuId) => {
  await setDoc(doc(db, coll, docuId), {
    address: payload.address,
    email: payload.email,
    phone: payload.phone,
    time: payload.time
  }).then(() => {
    notify({
      type: "success",
      title: `Update`,
      text: "Updated site info",
      }); 
  })
}

export const deleteReview = async (coll, id) => {
  await deleteDoc(doc(db, coll, id))
  .then(() => {
    notify({
      type: "error",
      title: `Delete`,
      text: "Deleted review",
      }); 
  })
}

